import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  mostrarJson(reportsID, $regID) {
    var url = ConfigAPI.baseURL + "reports/" + reportsID + "/json/" + $regID + Session.getToken();
    return instance.get(url);
  },  
  mostrarVariable(reportsID, $regID) {
    var url = ConfigAPI.baseURL + "reports/" + reportsID + "/variable/" + $regID + Session.getToken();
    return instance.get(url);
  },    
  mostrarRender(reportsID, $regID) {
    var url = ConfigAPI.baseURL + "reports/" + reportsID + "/render/" + $regID + Session.getToken();
    return instance.get(url);
  },    
  editar(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "reports/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },  
  generate(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "reports/generate/pdf" + Session.getToken();
    return instance.post(url,params);
  },    
}

export default servicesAPI;
